body {
  margin: 0;
  color: #000;

  /* font-family: Betatron; */
  font-family: CA Saygon Text;

}
@font-face {
  font-family: Betatron;
  src: url('fonts/Betatron-Regular.otf') format('opentype');
}

@font-face {
  font-family: Coanda TRIAL;
  src: url('fonts/FontsFree-Net-Coanda-Bold.ttf') format('opentype');
}

@font-face {
  font-family: CA Saygon Text;
  src: url('fonts/casaygontext-regular-2-webfont.woff') format('opentype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
:root {
  --primary-color: #22caa1;
  --white: #fff;
  --border-color: #edf1fd;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* * {
  box-sizing: border-box;
} */
/* h1,
h2,
h4,
h5,
h6 {
  margin: 0 !important;
}
p {
  margin: 0 !important;
} */

/* a {
  color: #fff !important;
  text-decoration: none !important;
}

a.opensea {
  color: #000000 !important;
  text-decoration: underline !important;
  font-weight: bold;
} */

.swiper-button-next,
.swiper-button-prev {
  top: 60% !important;
}

object {
  cursor: pointer;
}

object:hover {
  cursor: pointer;
}

object #play-next{
  cursor: pointer !important;
}

#play-next{
  cursor: pointer !important;
}
