.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nomint {
  color: #ff0000;
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.art {
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 500px;
  height: 500px;
  overflow: hidden;
  margin-right: 50px;
  float: left;
  box-shadow: 4px 4px 3px 0px;
}

.art body {
  margin: 0px !important;
}

.button {
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;

  text-align: right;

  color: #2b2727;
  outline: 0;
  background: #000;
  border: 1px solid #2b2727;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  max-width: 200px;
  transition: 0.4s;
  color: #fff;
  float: right;
}
.button-animate {
  max-width: 500px;
  transition: 0.4s;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.art-main {
  border: 1px solid #cccccc;
  border-radius: 6px;
  overflow: hidden;
  float: left;
  grid-row: 1/3;
  grid-column: 1/3;
  box-shadow: 4px 4px 7px 0px;
}

.art-description {
  border: 1px solid #0000004f;
  font-family: CA Saygon Text;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
  float: left;
  max-height: 200px;
  box-shadow: 4px 4px 7px 0px;
}

.art-description table {
  width: 100%;
  width: 100%;
}

.art-description span {
  /* font-family: 'Roboto Mono',monospace; */
  font-family: CA Saygon Text;
  font-style: normal;
  font-weight: normal;
  color: #828282;
}

.art-description a {
  color: #828282 !important;
  text-decoration: underline !important;
}

.thumb a {
  color: #828282 !important;
  text-decoration: underline !important;
}

.song-lib-header {
  font-family: Betatron;
  padding-bottom: 10px;
}

.static-songs {
  font-family: CA Saygon Text;
  border: 1px solid #0000004f;
  border-radius: 6px;
  width: 100%;
  height: 300px;
  max-height: 300px;
  overflow: hidden;
  float: left;
  box-shadow: 4px 4px 7px 0px;
}

.song-description {
  border: 1px solid #0000004f;
  border-radius: 6px;
  overflow: hidden;
  grid-row: 2;
  grid-column: 4;
  width: 100%;
  text-align: center;
}

.centered-container-1 {
  text-align: left;
  margin-right: 35px;
  margin-left: 35px;
  grid-column: 3/5;
  grid-row: 1;
}

.midi-column {
  text-align: center !important;
}

.play-button {
  font-family: CA Saygon Text;
  width: '20px';
}

.song-name {
  width: '30px';
}
.mint-nft {
  width: '30%';
}

.centered-container-1 table {
  table-layout: auto;
  width: 100%;
}
.centered-container-1 td {
  width: 40%;
  word-wrap: break-word;
}

.centered-container-2 {
  /* text-align: left; */
  /* margin-left: 10px; */
  grid-column: 3 / 5;
  grid-row: 2;
  margin-top: 10px;
  width: max-content;
}

.click-to-play {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: normal;
  color: #828282;
  text-align: left;
  clear: both;
  grid-column: 5/1;
  grid-row: 4;
  width: 350px;
  margin-top: 10px;
}

.collection-header {
  grid-row: 5;
  grid-column: 1;
  text-align: left;
  color: #828282;
  text-align: left;
}

.play-new-song {
  overflow: hidden;
  grid-column: 3;
  grid-row: 2;
  margin-left: 35px;
  margin-top: 100px;
}

.song-row {
  background-color: #0000004f !important;
}

.midi-icon {
  width: 25%;
  height: 25%;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.static-songs table {
  width: 100%;
  width: 100%;
  table-layout: fixed;
}

.static-songs span {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: normal;
  color: #828282;
  cursor: pointer;
}

.static-songs thead {
  background-color: #2b2727 !important;
  color: #f8f8f8;
}

.static-songs tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
  color: #fff;
}

.static-songs td:nth-child(1) {
  width: 5%;
}

/* the second */
.static-songs td:nth-child(2) {
  width: 40%;
}

/* the third */
.static-songs td:nth-child(3) {
  width: 25%;
}

.static-songs td:nth-child(4) {
  width: 25%;
  text-align: center !important;
}

.art-description tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}

.thumb {
  border-radius: 6px;
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin-right: 20px;
  margin-top: 20px;

  float: left;
}

.thumb img {
  max-width: 100%;
  max-height: 90%;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 4px 4px 3px 0px;
}

#play-next {
  cursor: pointer;
}

#stop {
  cursor: pointer;
}

.thumb span {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: normal;
  color: #828282;
}

.thumb-container {
  margin-top: 50px;
  vertical-align: top;
  text-align: center;
  grid-row: 5;
  grid-column: 1/5;
}

.thumb-container span {
  display: block;
}

.thumb-container2 {
  margin-top: 50px;
  vertical-align: top;
  text-align: center;
  grid-row: 6;
  grid-column: 1/5;
}

.thumb-container2 span {
  display: block;
}

.thumbnailimg {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.thumbnail-name {
  font-size: 30px;
  color: rgb(17, 7, 7) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#circus {
  float: left; /* add this */
}
#song-container {
  overflow: hidden; /* will contain if #first is longer than #second */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

#song-container-non-holders {
  overflow: hidden; /* will contain if #first is longer than #second */
}

#waikiki {
  float: left;
  overflow: hidden;
}
#syncity {
  overflow: hidden;
}

.countdown {
  font-size: 18px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */

  .song-container-non-holders .art {
    width: 300px !important;
    height: 300px;
  }

  .thoon {
    width: 300px !important;
    height: 300px;
  }
}

.spin-center {
  transform-origin: center;
  transform-box: fill-box;
  animation: rotate-wheel360 3s infinite;
}

.spin-center-a {
  transform-origin: center;
  transform-box: fill-box;
  animation: rotate-wheel360a 3s infinite;
}

.bobble {
  transform-origin: right;
  transform-box: fill-box;
  animation: bobble 250ms infinite;
}

.small-bobble {
  transform-origin: right;
  transform-box: fill-box;
  animation: small-bobble 250ms infinite;
}

.small-bobble-fast {
  transform-origin: right;
  transform-box: fill-box;
  animation: small-bobble 125ms infinite;
}

.small-bobble-down {
  transform-origin: right;
  transform-box: fill-box;
  animation: small-bobble-down 250ms infinite;
}

.inline-svg {
  width: 1em;
  vertical-align: middle;
}

@keyframes small-bobble {
  0% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(5px, 0px, 0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes small-bobble-down {
  0% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(0px, 5px, 0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes bobble {
  0% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(20px, 0px, 0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes rotate-wheel360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-wheel360a {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.neon {
  width: 100%;
  height: auto;
  animation: flicker 7s linear infinite;
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.6;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.6;
  }

  14% {
    opacity: 0.95;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  37% {
    opacity: 0.73;
  }

  38% {
    opacity: 0.5;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  56% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  82% {
    opacity: 0.5;
  }

  93% {
    opacity: 0.73;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
